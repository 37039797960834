import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import { EActions, IMultiFaPrivateKeyBody } from './types'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'

export const useSettingsService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const sendCodeToCurrentEmail = async () => {
    const body = {}
    await httpClient.post<IApiResponse<any>>(`/api/user/change-email-request`, body)
  }
  const sendCodeToNewEmail = async () => {
    const body = {}
    await httpClient.post<IApiResponse<any>>(`/api/user/change-email-request-to-new-email`, body)
  }
  const confirmCodeFromNewEmail = async (body: { twoFactorToken?: string }, code: string) => {
    return await httpClient.post<IApiResponse<any>>(
      `/api/user/change-email-request-to-new-email/verify/${code}`,
      body
    )
  }
  const disableEmailTwoFa = async (body: { code?: string }) => {
    return await httpClient.post<IApiResponse<any>>(`/api/user/deactivate-email-2fa`, body)
  }
  const emailResend = async () => {
    return await httpClient.post<IApiResponse<any>>(`/api/user/email/resend`)
  }
  const verifyCurrentEmailCode = async (code: string) => {
    const body = {}
    await httpClient.post<IApiResponse<any>>(`/api/user/change-email-request/verify/${code}`, body)
  }
  const changeEmail = async (body: { email: string }) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/change-email`, body)
  }
  const updateEmailState = (email: string) => {
    dispatch({ type: EActions.SET_NEW_EMAIL_ON_CHANGE, newEmail: email })
  }

  const getUserPrivateKey = async (
    body: IMultiFaPrivateKeyBody,
    merchantId: string,
    walletId: string
  ) => {
    return await httpClient.post<IApiResponse<any>>(
      `/api/wallet/private-key/${merchantId}/${walletId}`,
      body
    )
  }

  return {
    state,
    sendCodeToCurrentEmail,
    verifyCurrentEmailCode,
    changeEmail,
    updateEmailState,
    sendCodeToNewEmail,
    confirmCodeFromNewEmail,
    getUserPrivateKey,
    disableEmailTwoFa,
    emailResend
  }
}
