import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'

import { useSettingsService } from './service'
import {
  ISettingsContext,
  IProfileBody,
  ITwoFactorBody,
  IEmailResendCodeBody,
  IEmailTwoFaBody,
} from './types'
import initialState from './state'

const SettingsContext = createContext<ISettingsContext>({
  state: initialState(),
  getProfile: async () => {},
  updateProfile: async (body: IProfileBody) => {},
  verifyChangeEmail: async (secretKey: string) => {},
  getTwoFactor: async () => {},
  resetTwoFactor: async () => {},
  activateTwoFactor: async (body: ITwoFactorBody) => {},
  deactivateTwoFactor: async (twoFactorToken: string) => {},
  emailTwoFaResendCode: async (body: IEmailResendCodeBody) => {},
  activateEmailTwoFa: async (body: IEmailTwoFaBody) => {},
  emailMultiFaResendCode: async (body: IEmailResendCodeBody) => {},
  emailResendCode: async (body: IEmailResendCodeBody) => {}

})

export const SettingsProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useSettingsService()
  return <SettingsContext.Provider value={service}>{children}</SettingsContext.Provider>
}

const useSettingsContext = () => useContext(SettingsContext)

export default useSettingsContext
