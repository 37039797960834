import { IReducerAction } from '@/types/other/state.interface'
import { IAssetBalanceItem } from '@/types/api/balance/assets.interface'
import { ICurrency } from '@/types/api/swap/currency.interface'
import { ISwapEsimateItem } from '@/types/api/swap/estimate.interface'
import { ISwapResponse } from '@/types/api/swap/swap.interface'
import { IEstimateMaxBody } from '@/types/api/withdrawal/withdrawal.interface'

export interface IEstimateMaxParams {
  merchantId: string
  walletId: string
}

export type ISwapAssetModel = {
  name: string
  network: string
  currency: string
  balance: string
  usdBalance: string
  id: string
  walletAddress: string
  walletId: string
  decimals: number
}

export type ISwapAccountModel = {
  name: string
  avatar: string
  address: string
  currency: string
  balance: string
  usdBalance: string
}

export type ISwapOfferModel = {
  name: string
  trustScore: number
  supportRate: number
  kycRisk: ESwapOfferKYCRisk
  currency: string
  amount: string
  offerType: ESwapOfferType
  eta: string
  logoUrl: string
  fromAmount: string
  fromCurrency: string
  trustLink: string
  id: string
  fromNetwork: string
  toNetwork: string
}

export enum ESwapOfferKYCRisk {
  rare = 'rare',
  medium = 'medium',
  low = 'low',
  required = 'required'
}

export enum ESwapOfferType {
  fixed = 'Fixed',
  floating = 'Floating'
}

export interface ISwapState {
  assets: IAssetBalanceItem[]
  assetForSend: ISwapAssetModel | null
  assetForReceive: ISwapAssetModel | null
  sendAmount: string | null
  preSwapPage: string
  estimates: ISwapEsimateItem[]
  offer: ISwapOfferModel | null
  error: any
  swapData: ISwapResponse | null
}

export interface ISwapEstimateParams {
  fromId: string
  toId: string
  amount: string
  sort: string
}

export interface ISwapParams {
  currencyFromId: string
  toId: string
  currencyToId: string
  amount: number
  partner: string
  fixed: boolean
  rateId?: string
  twoFactorToken?: string
  emailOtp?: string
  fromId: string
}

export interface ISwapContext {
  state: ISwapState
  getAssets: (assets: IAssetBalanceItem[], currencies: ICurrency[]) => Promise<any>
  setAssetForSend: (asset: ISwapAssetModel) => void
  setAssetForReceive: (asset: ISwapAssetModel | null) => void
  replaceAssets: () => void
  setSendAmount: (value: string) => void
  estimateSwap: () => Promise<any>
  setOffer: (offer: ISwapOfferModel) => void
  swap: (params: ISwapParams) => Promise<any>
  estimateMax: (params: IEstimateMaxParams, body: IEstimateMaxBody) => Promise<any>
  resetState: () => void
  resetEstimates: () => void
  updatePreSwapPage: (page: string) => void
}

export enum EActions {
  GET_ASSETS = 'GET_ASSETS',
  SET_ASSET_FOR_SEND = 'SET_ASSET_FOR_SEND',
  SET_ASSET_FOR_RECEIVE = 'SET_ASSET_FOR_RECEIVE',
  SET_SEND_AMOUNT = 'SET_SEND_AMOUNT',
  SET_ESTIMATES = 'SET_ESTIMATES',
  SET_OFFER = 'SET_OFFER',
  SET_SWAP_SUCCESS = 'SET_SWAP_SUCCESS',
  SET_SWAP_ERROR = 'SET_SWAP_ERROR',
  RESET_STATE = 'RESET_STATE',
  PRE_SWAP_PAGE = 'PRE_SWAP_PAGE'
}

export enum ESwapActionType {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE'
}

export interface IAssetActionGet extends IReducerAction<EActions.GET_ASSETS> {
  assets: IAssetBalanceItem[]
}

export interface IAssetActionSetAssetForSend extends IReducerAction<EActions.SET_ASSET_FOR_SEND> {
  asset: ISwapAssetModel
}

export interface IAssetActionSetAssetForReceive
  extends IReducerAction<EActions.SET_ASSET_FOR_RECEIVE> {
  asset: ISwapAssetModel | null
}

export interface IActionSetAmountForSend extends IReducerAction<EActions.SET_SEND_AMOUNT> {
  sendAmount: string
}

export interface IActionSetEstimates extends IReducerAction<EActions.SET_ESTIMATES> {
  estimates: ISwapEsimateItem[]
}

export interface IActionSetOffer extends IReducerAction<EActions.SET_OFFER> {
  offer: ISwapOfferModel
}

export interface IActionSetSwapError extends IReducerAction<EActions.SET_SWAP_ERROR> {
  error: any
}

export interface IActionSetSwapSucess extends IReducerAction<EActions.SET_SWAP_SUCCESS> {
  swapData: ISwapResponse
}
export interface IActionUpdateSwapPageState extends IReducerAction<EActions.PRE_SWAP_PAGE> {
  preSwapPage: string
}

export interface IActionResetState extends IReducerAction<EActions.RESET_STATE> {}

export type Actions =
  | IAssetActionGet
  | IAssetActionSetAssetForSend
  | IAssetActionSetAssetForReceive
  | IActionSetAmountForSend
  | IActionSetEstimates
  | IActionSetOffer
  | IActionSetSwapError
  | IActionSetSwapSucess
  | IActionResetState
  | IActionUpdateSwapPageState
