import { ReferralsState } from './types'

const initialState = (): ReferralsState => ({
  referrals: {
    refId: '',
    earn: 0,
    minWithdrawal: 0,
    rewardPercent: 0,
    refs: 0
  },
  withdraw: {
    amount: 0,
    to: '',
    currency: ''
  },
  estimateWithdrawalData: {
    estimationId: '',
    receive: 0,
    amount: 0,
    fee: 0,
    minerFee: 0,
    to: ''
  }
})

export default initialState
