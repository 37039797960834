import initialState from './state'
import { Actions, EActions, ISwapState } from './types'

export const reducer = (state: ISwapState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_ASSETS: {
      return {
        ...state,
        assets: action.assets
      }
    }
    case EActions.SET_ASSET_FOR_SEND: {
      return {
        ...state,
        assetForSend: action.asset
      }
    }
    case EActions.SET_ASSET_FOR_RECEIVE: {
      return {
        ...state,
        assetForReceive: action.asset
      }
    }
    case EActions.PRE_SWAP_PAGE: {
      return {
        ...state,
        preSwapPage: action.preSwapPage
      }
    }
    case EActions.SET_SEND_AMOUNT: {
      return {
        ...state,
        sendAmount: action.sendAmount
      }
    }
    case EActions.SET_ESTIMATES: {
      return {
        ...state,
        estimates: action.estimates
      }
    }
    case EActions.SET_OFFER: {
      return {
        ...state,
        offer: action.offer
      }
    }
    case EActions.SET_SWAP_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }
    case EActions.SET_SWAP_SUCCESS: {
      return {
        ...state,
        swapData: action.swapData
      }
    }
    case EActions.RESET_STATE: {
      return {
        ...state,
        preSwapPage: state.preSwapPage
      }
    }
  }
}
