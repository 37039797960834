import { IReducerAction } from '@/types/other/state.interface'

export interface IMultiFaBody {
  twoFactorToken: string
  code: string
}
export interface IMultiFaPrivateKeyBody {
  twoFactorToken?: string
  emailOtp?: string
}
export interface ChangeEmailState {
  newEmail: string
}
export interface IChangeEmailContext {
  state: ChangeEmailState
  updateEmailState: (email: string) => void
  sendCodeToCurrentEmail: () => Promise<any>
  sendCodeToNewEmail: () => Promise<any>
  verifyCurrentEmailCode: (code: string) => Promise<any>
  confirmCodeFromNewEmail: (body: { twoFactorToken?: string }, code: string) => Promise<any>
  changeEmail: (body: { email: string }) => Promise<any>
  disableEmailTwoFa: (body: { code?: string }) => Promise<any>
  emailResend: () => Promise<any>
  getUserPrivateKey: (
    body: IMultiFaPrivateKeyBody,
    merchantId: string,
    walletId: string
  ) => Promise<any>
}

export enum EActions {
  SET_NEW_EMAIL_ON_CHANGE = 'SET_NEW_EMAIL_ON_CHANGE'
}

export interface ISetEmailOnChange extends IReducerAction<EActions.SET_NEW_EMAIL_ON_CHANGE> {
  newEmail: any
}
export type Actions = ISetEmailOnChange
