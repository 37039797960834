export const ROUTES = {
  DEFAULT: {
    key: '/',
    path: '/'
  },
  INITIAL: {
    key: 'initial',
    path: '/initial'
  },
  HERO: {
    key: 'hero',
    path: '/initial/hero'
  },
  CREATE_WALLET: {
    key: 'create-wallet',
    path: '/initial/create-wallet'
  },
  CREATE_WALLET_READY: {
    key: 'create-wallet-ready',
    path: '/initial/create-wallet-ready'
  },
  BACK_UP: {
    key: 'back-up',
    path: '/initial/back-up'
  },
  BACK_UP_PASS_PHRASE: {
    key: 'pass-phrase',
    path: '/initial/back-up/pass-phrase'
  },
  BACK_UP_FORM: {
    key: 'form',
    path: '/initial/back-up/form'
  },
  BACK_UP_COMPLETED: {
    key: 'completed',
    path: '/initial/back-up/completed'
  },
  RECOVERY_PHRASE_IMPORT: {
    key: 'recovery-phrase-import',
    path: '/initial/recovery-phrase-import'
  },
  RECOVERY_PHRASE_IMPORT_FORM: {
    key: 'form',
    path: '/initial/recovery-phrase-import/form'
  },
  UI_KIT: {
    key: 'ui-kit',
    path: '/ui-kit'
  },
  USER: {
    key: 'user',
    path: '/user'
  },
  WALLET: {
    key: 'wallet',
    path: '/user/wallet'
  },
  WALLET_REFERRAL_PROGRAM: {
    key: 'wallet/referral-program',
    path: '/user/wallet/referral-program'
  },
  WALLET_REFERRAL_PROGRAM_SELECT_NETWORK: {
    key: 'wallet/referral-program/networks',
    path: '/user/wallet/referral-program/networks'
  },
  WALLET_REFERRAL_PROGRAM_FORM: {
    key: 'wallet/referral-program/form',
    path: '/user/wallet/referral-program/form'
  },
  WALLET_REFERRAL_PROGRAM_COMPLETE: {
    key: 'wallet/referral-program/complete',
    path: '/user/wallet/referral-program/complete'
  },
  WALLET_REFERRAL_PROGRAM_CONFIRM: {
    key: 'wallet/referral-program/confirm',
    path: '/user/wallet/referral-program/confirm'
  },
  WALLET_LIST: {
    key: 'wallet/list',
    path: '/user/wallet/list'
  },
  WALLET_ADD: {
    key: 'wallet/add',
    path: '/user/wallet/add'
  },
  WALLET_ADD_METHOD: {
    key: 'method',
    path: '/user/wallet/add/method'
  },
  WALLET_ADD_NEW: {
    key: 'new',
    path: '/user/wallet/add/new'
  },
  WALLET_ADD_ADD_EXISTING: {
    key: 'add-existing',
    path: '/initial/recovery-phrase-import/form'
  },
  WALLET_IMPORT: {
    key: 'import',
    path: '/initial/import'
  },
  WALLET_IMPORT_METHOD: {
    key: 'method',
    path: '/initial/import/method'
  },
  WALLET_IMPORT_RECOVERY: {
    key: 'recovery',
    path: '/initial/import/recovery'
  },
  WALLET_IMPORT_PRIVATE: {
    key: 'private',
    path: '/initial/import/private'
  },
  WALLET_IMPORT_CREATE: {
    key: 'create',
    path: '/initial/import/create'
  },
  SETTINGS: {
    key: 'settings',
    path: '/user/settings'
  },
  SETTINGS_LANGUAGE: {
    key: 'settings/language',
    path: '/user/settings/language'
  },
  SETTINGS_PRIVATE_KEY: {
    key: 'settings/private-key',
    path: '/user/settings/private-key'
  },
  SETTINGS_LOGOUT: {
    key: 'settings/logout',
    path: '/user/settings/logout'
  },
  SETTINGS_CHANGE_EMAIL: {
    key: 'settings/change-email',
    path: '/user/settings/change-email'
  },
  SETTINGS_VERIFICATION_EMAIL_CODE: {
    key: 'settings/verification-email-code',
    path: '/user/settings/verification-email-code'
  },
  SETTINGS_VERIFICATION_EMAIL_STATUS: {
    key: 'settings/verification-email-status',
    path: '/user/settings/verification-email-status'
  },
  SETTINGS_NEW_EMAIL: {
    key: 'settings/new-email',
    path: '/user/settings/new-email'
  },
  SETTINGS_NEW_EMAIL_CODE: {
    key: 'settings/new-email-code',
    path: '/user/settings/new-email-code'
  },
  TWO_FA_ACTIVATE: {
    key: 'settings/two-fa/activate',
    path: '/user/settings/two-fa/activate'
  },
  TWO_FA_CHANGE: {
    key: 'settings/two-fa/change',
    path: '/user/settings/two-fa/change'
  },
  TWO_FA_DISABLE: {
    key: 'settings/two-fa/disable',
    path: '/user/settings/two-fa/disable'
  },
  TWO_FA_EMAIL: {
    key: 'settings/two-fa/email',
    path: '/user/settings/two-fa/email'
  },
  STAKING: {
    key: 'staking',
    path: '/user/staking'
  },
  EARM: {
    key: 'earm',
    path: '/user/earm'
  },
  ASSET: {
    key: 'asset/:id',
    path: (id: string) => `/user/asset/${id}`
  },
  ASSET_DETAILS: {
    key: 'asset/:currencyId/details/:transactionId',
    path: (currencyId: string, transactionId: string) =>
      `/user/asset/${currencyId}/details/${transactionId}`
  },
  TRANSACTION_HISTORY: {
    key: 'transactions',
    path: '/user/transactions'
  },
  SEND: {
    key: 'send',
    path: '/user/send'
  },
  SEND_ASSETS_LIST: {
    key: 'assets/list',
    path: '/user/send/assets/list'
  },
  SEND_CURRENCY: {
    key: 'currency/:id',
    path: (id: string) => `/user/send/currency/${id}`
  },
  SEND_CURRENCY_CONFIRM: {
    key: 'currency/confirm/:id',
    path: (id: string) => `/user/send/currency/confirm/${id}`
  },
  SEND_CURRENCY_CHANGE_SPEED: {
    key: 'currency/change-speed/:id',
    path: (id: string) => `/user/send/currency/change-speed/${id}`
  },
  DEPOSIT: {
    key: 'deposit',
    path: '/user/deposit'
  },
  DEPOSIT_ASSETS_LIST: {
    key: 'assets/list',
    path: '/user/deposit/assets/list'
  },
  DEPOSIT_SELECTED: {
    key: 'selected',
    path: '/user/deposit/selected'
  },
  DEPOSIT_ADDRESS: {
    key: 'address/:id',
    path: (id: string) => `/user/deposit/address/${id}`
  },
  SWAP: {
    key: 'swap',
    path: '/user/swap'
  },
  SWAP_SUCCESS: {
    key: 'swap/success',
    path: '/user/swap/success'
  },
  SWAP_ERROR: {
    key: 'swap/error',
    path: '/user/swap/error'
  },
  SWAP_EXCHANGE: {
    key: 'swap/exchange',
    path: '/user/swap/exchange'
  },
  SWAP_ASSETS: {
    key: 'swap/assets',
    path: '/user/swap/assets'
  },
  SWAP_ACCOUNTS: {
    key: 'swap/accounts',
    path: '/user/swap/accounts'
  },
  SWAP_OFFERS: {
    key: 'swap/offers',
    path: '/user/swap/offers'
  },
  SWAP_CONFIRM: {
    key: 'swap/confirm',
    path: '/user/swap/confirm'
  },
  SWAP_HISTORY: {
    key: 'swap/history',
    path: '/user/swap/history'
  },
  SWAP_TRANSACTION: {
    key: 'swap/transaction',
    path: '/user/swap/transaction'
  }
}

export const ROUTES_WITHOUT_NAV = [
  ROUTES.WALLET_LIST.path,
  ROUTES.WALLET_ADD.path,
  ROUTES.WALLET_REFERRAL_PROGRAM.path,
  ROUTES.WALLET_REFERRAL_PROGRAM_SELECT_NETWORK.path,
  ROUTES.WALLET_REFERRAL_PROGRAM_FORM.path,
  ROUTES.WALLET_REFERRAL_PROGRAM_COMPLETE.path,
  ROUTES.WALLET_REFERRAL_PROGRAM_CONFIRM.path,
  ROUTES.WALLET_ADD_ADD_EXISTING.path,
  ROUTES.WALLET_ADD_NEW.path,
  ROUTES.SETTINGS_CHANGE_EMAIL.path,
  ROUTES.SETTINGS_VERIFICATION_EMAIL_CODE.path,
  ROUTES.SETTINGS_NEW_EMAIL.path,
  ROUTES.SETTINGS_NEW_EMAIL_CODE.path,
  ROUTES.SETTINGS_LOGOUT.path,
  ROUTES.SETTINGS_PRIVATE_KEY.path,
  ROUTES.TWO_FA_ACTIVATE.path,
  ROUTES.TWO_FA_CHANGE.path,
  ROUTES.TWO_FA_DISABLE.path,
  ROUTES.TWO_FA_EMAIL.path,
  ROUTES.SEND_CURRENCY.path(''),
  ROUTES.SEND_CURRENCY_CONFIRM.path(''),
  ROUTES.SEND_CURRENCY_CHANGE_SPEED.path(''),
  ROUTES.SWAP.path,
  ROUTES.SWAP_SUCCESS.path,
  ROUTES.SWAP_ERROR.path,
  ROUTES.SWAP_EXCHANGE.path,
  ROUTES.SWAP_ASSETS.path,
  ROUTES.SWAP_ACCOUNTS.path,
  ROUTES.SWAP_OFFERS.path,
  ROUTES.SWAP_CONFIRM.path,
  ROUTES.SWAP_HISTORY.path,
  ROUTES.SWAP_TRANSACTION.path
]
