import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import {
  EActions,
  IProfileBody,
  ITwoFactorBody,
  IEmailResendCodeBody,
  IEmailTwoFaBody,
  IMultiFaBody
} from './types'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'

export const useSettingsService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getProfile = async (): Promise<any> => {
    const { data } = await httpClient.get<IApiResponse<any>>('/api/user/profile')
    dispatch({ type: EActions.GET_PROFILE, profile: data })
  }

  const updateProfile = async (body: IProfileBody) => {
    await httpClient.patch<IApiResponse<any>>(`/api/user/profile`, body)
  }

  const verifyChangeEmail = async (secretKey: string) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/changeEmail/verify/${secretKey}`, {})
  }

  const getTwoFactor = async (): Promise<any> => {
    const { data } = await httpClient.get<IApiResponse<any>>('/api/user/two-factor')
    dispatch({ type: EActions.GET_TWO_FACTOR, twoFactor: data.data })
  }

  const resetTwoFactor = async (): Promise<any> => {
    dispatch({ type: EActions.GET_TWO_FACTOR, twoFactor: null })
  }

  const activateTwoFactor = async (body: ITwoFactorBody) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/confirm-two-factor`, body)
  }

  const deactivateTwoFactor = async (twoFactorToken: string) => {
    await httpClient.delete<IApiResponse<any>>(`/api/user/delete-two-factor/${twoFactorToken}`)
  }

  const emailTwoFaResendCode = async (body: IEmailResendCodeBody) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/email/resend/5`, body)
  }

  const activateEmailTwoFa = async (body: IEmailTwoFaBody) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/activate-email-2fa`, body)
  }

  const emailMultiFaResendCode = async (body: IEmailResendCodeBody) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/email/resendCode/TwoFa`, body)
  }
  const emailResendCode = async (body: IEmailResendCodeBody) => {
    await httpClient.post<IApiResponse<any>>(`/api/user/email/resendCode`, body)
  }
  
  return {
    state,
    getProfile,
    updateProfile,
    verifyChangeEmail,
    getTwoFactor,
    resetTwoFactor,
    activateTwoFactor,
    deactivateTwoFactor,
    emailTwoFaResendCode,
    activateEmailTwoFa,
    emailMultiFaResendCode,
    emailResendCode
  }
}
