import { createBrowserRouter } from 'react-router-dom'
import { lazy } from 'react'

import { BackupProvider } from './providers/back-up'
import { BalanceProvider } from './providers/balance'
import { WalletProvider } from './providers/wallet'
import { NetworkProvider } from './providers/networks'
import { ImportProvider } from './providers/import'
import { ReferralsProvider } from './providers/referrals'
import { SettingsProvider } from './providers/settings'
import { WithdrawalProvider } from './providers/withdrawal'
import { SwapProvider } from './providers/swap'
import { SwapHistoryProvider } from './providers/swap/providers/history'
import { ChangeEmailProvider } from './providers/change-email'
import { WebSocketProvider } from './providers/websocket'

import WalletGuard from './guards/wallet-guard'

import { ROUTES } from './constants/routes'

const UiKit = lazy(() => import('@/pages/ui-kit/UiKit'))
const App = lazy(() => import('@/App'))
const Wallet = lazy(() => import('@/pages/wallet-page/Wallet'))
const Settings = lazy(() => import('@/pages/settings-page/settings/Settings'))
const Staking = lazy(() => import('@/pages/staking-page/Staking'))
const Earm = lazy(() => import('@/pages/earm-page/Earm'))
const NotFound = lazy(() => import('@/pages/not-found-page/NotFound'))
const InitiaLayout = lazy(() => import('@/layouts/initial-layout/InitiaLayout'))
const UserLayout = lazy(() => import('@/layouts/user-layout/UserLayout'))
const Hero = lazy(() => import('@/pages/hero-page/hero/Hero'))
const CreateWallet = lazy(() => import('@/pages/create-wallet-page/create-wallet/CreateWallet'))
const CreateWalletReady = lazy(
  () => import('@/pages/create-wallet-page/create-wallet-ready/CreateWalletReady')
)
const BackUp = lazy(() => import('@/pages/back-up-page/back-up/BackUp'))
const BackUpPassPhrase = lazy(
  () => import('@/pages/back-up-page/back-up-pass-phrase/BackUpPassPhrase')
)
const BackUpForm = lazy(() => import('@/pages/back-up-page/back-up-form/BackUpForm'))
const WalletsList = lazy(() => import('@/pages/wallets-list-page/WalletsList'))
const AddWalletMethod = lazy(
  () => import('@/pages/add-wallet-page/add-method-page/AddWalletMethod')
)
const AddWallet = lazy(() => import('@/pages/add-wallet-page/add-wallet/AddWallet'))
const NewWallet = lazy(() => import('@/pages/add-wallet-page/new/NewWallet'))
const ImportWalletMethod = lazy(
  () => import('@/pages/import-wallet-page/import-method/ImportWalletMethod')
)
const ImportWallet = lazy(() => import('@/pages/import-wallet-page/import-wallet/ImportWallet'))
const ImportRecovery = lazy(
  () => import('@/pages/import-wallet-page/import-recovery/ImportRecovery')
)
const ImportPrivate = lazy(() => import('@/pages/import-wallet-page/import-private/ImportPrivate'))
const ImportCreateWallet = lazy(
  () => import('@/pages/import-wallet-page/import-create-wallet/ImportCreateWallet')
)
const BackUpCompleted = lazy(() => import('@/pages/back-up-page/back-up-completed/BackUpCompleted'))
const RecoveryPhraseImport = lazy(
  () => import('@/pages/recovery-phrase-import-page/recovery-phrase-import/RecoveryPhraseImport')
)
const RecoveryPhraseImportForm = lazy(
  () =>
    import(
      '@/pages/recovery-phrase-import-page/recovery-phrase-import-form/RecoveryPhraseImportForm'
    )
)
const AssetPage = lazy(() => import('@/pages/asset-page/asset-page/AssetPage'))
const AssetDetailsPage = lazy(
  () => import('@/pages/asset-page/asset-details-page/AssetDetailsPage')
)
const TransactionsPage = lazy(() => import('@/pages/transactions-page/Transactions'))
const ReferralProgram = lazy(
  () => import('@/pages/referral-program-page/referral-program/ReferralProgram')
)
const ReferralProgramNetworks = lazy(
  () => import('@/pages/referral-program-page/referral-program-networks/ReferralProgramNetworks')
)
const ReferralProgramForm = lazy(
  () => import('@/pages/referral-program-page/referral-program-form/ReferralProgramForm')
)
const ReferralProgramComplete = lazy(
  () => import('@/pages/referral-program-page/referral-program-complete/ReferralProgramComplete')
)
const ReferralProgramConfirm = lazy(
  () => import('@/pages/referral-program-page/referral-program-confirm/ReferralProgramConfirm')
)
const Deposit = lazy(() => import('@/pages/deposit-page/deposit/Deposit'))
const DepositList = lazy(() => import('@/pages/deposit-page/deposit-list/DepositList'))
const DepositAddress = lazy(() => import('@/pages/deposit-page/deposit-address/DepositAddress'))

const Send = lazy(() => import('@/pages/send-page/send/Send'))
const SendAssetsList = lazy(() => import('@/pages/send-page/send-list/SendAssetsList'))
const SendConfirm = lazy(() => import('@/pages/send-page/send-confirm/SendConfirm'))
const SendChangeSpeed = lazy(() => import('@/pages/send-page/send-change-speed/SendChangeSpeed'))

const SendCurrency = lazy(() => import('@/pages/send-page/send-currency/SendCurrency'))
const TwoFaActivate = lazy(() => import('@/pages/two-fa-page/two-fa-activate/TwoFaActivate'))
const TwoFaChange = lazy(() => import('@/pages/two-fa-page/two-fa-change/TwoFaChange'))
const TwoFaDisable = lazy(() => import('@/pages/two-fa-page/two-fa-disable/TwoFaDisable'))
const TwoFaEmail = lazy(() => import('@/pages/two-fa-page/two-fa-email/TwoFaEmail'))
const LanguagesSettings = lazy(
  () => import('@/pages/settings-page/languages-settings/LanguagesSettings')
)
const SettingsPrivateKey = lazy(
  () => import('@/pages/settings-page/settings-private-key-page/SettingsPrivateKey')
)
const Logout = lazy(() => import('@/pages/settings-page/logout-page/Logout'))
const ChangeEmailForm = lazy(
  () => import('@/pages/settings-page/change-email-page/change-email-form/ChangeEmailForm')
)
const VerificationEmailCode = lazy(
  () =>
    import(
      '@/pages/settings-page/verification-email-page/verification-email-code/VerificationEmailCode'
    )
)
const VerificationEmailStatus = lazy(
  () =>
    import('@/pages/settings-page/verification-email-page/verification-status/VerificationStatus')
)
const NewEmailForm = lazy(
  () => import('@/pages/settings-page/change-email-page/new-email-form/NewEmailForm')
)
const NewEmailCodeForm = lazy(
  () => import('@/pages/settings-page/change-email-page/new-email-code-form/NewEmailCodeForm')
)
const Swap = lazy(() => import('@/pages/swap-page/swap/Swap'))
const SwapSuccess = lazy(() => import('@/pages/swap-page/swap-success/SwapSuccess'))
const SwapError = lazy(() => import('@/pages/swap-page/swap-error/SwapError'))
const SwapExchange = lazy(() => import('@/pages/swap-page/swap-exchange/SwapExchange'))
const SwapAssets = lazy(() => import('@/pages/swap-page/swap-assets/SwapAssets'))
const SwapAccounts = lazy(() => import('@/pages/swap-page/swap-accounts/SwapAccounts'))
const SwapOffers = lazy(() => import('@/pages/swap-page/swap-offers/SwapOffers'))
const SwapConfirm = lazy(() => import('@/pages/swap-page/swap-confirm/SwapConfirm'))
const SwapHistory = lazy(() => import('@/pages/swap-page/swap-history/SwapHistory'))
const SwapTransaction = lazy(() => import('@/pages/swap-page/swap-transaction/SwapTransaction'))

export const routes = createBrowserRouter([
  {
    path: ROUTES.DEFAULT.key,
    element: (
      <WalletProvider>
        <BalanceProvider>
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </BalanceProvider>
      </WalletProvider>
    ),
    children: [
      {
        path: ROUTES.INITIAL.key,
        element: <InitiaLayout />,
        children: [
          {
            path: ROUTES.HERO.key,
            element: <Hero />
          },
          {
            path: ROUTES.CREATE_WALLET.key,
            element: <CreateWallet />
          },
          { path: ROUTES.CREATE_WALLET_READY.key, element: <CreateWalletReady /> },
          {
            path: ROUTES.BACK_UP.key,
            element: (
              <BackupProvider>
                <BackUp />
              </BackupProvider>
            ),
            children: [
              { path: ROUTES.BACK_UP_PASS_PHRASE.key, element: <BackUpPassPhrase /> },
              { path: ROUTES.BACK_UP_FORM.key, element: <BackUpForm /> },
              { path: ROUTES.BACK_UP_COMPLETED.key, element: <BackUpCompleted /> }
            ]
          },
          {
            path: ROUTES.RECOVERY_PHRASE_IMPORT.key,
            element: <RecoveryPhraseImport />,
            children: [
              {
                path: ROUTES.RECOVERY_PHRASE_IMPORT_FORM.key,
                element: <RecoveryPhraseImportForm />
              }
            ]
          },
          {
            path: ROUTES.WALLET_IMPORT.key,
            element: (
              <ImportProvider>
                <NetworkProvider>
                  <ImportWallet />
                </NetworkProvider>
              </ImportProvider>
            ),
            children: [
              {
                path: ROUTES.WALLET_IMPORT_METHOD.key,
                element: <ImportWalletMethod />
              },
              {
                path: ROUTES.WALLET_IMPORT_RECOVERY.key,
                element: <ImportRecovery />
              },
              {
                path: ROUTES.WALLET_IMPORT_PRIVATE.key,
                element: <ImportPrivate />
              },
              {
                path: ROUTES.WALLET_IMPORT_CREATE.key,
                element: <ImportCreateWallet />
              }
            ]
          }
        ]
      },
      {
        path: ROUTES.UI_KIT.key,
        element: <UiKit />
      },
      {
        path: ROUTES.USER.key,
        element: (
          <WalletGuard>
            <ReferralsProvider>
              <NetworkProvider>
                <ChangeEmailProvider>
                  <SettingsProvider>
                    <WithdrawalProvider>
                      <SwapProvider>
                        <UserLayout />
                      </SwapProvider>
                    </WithdrawalProvider>
                  </SettingsProvider>
                </ChangeEmailProvider>
              </NetworkProvider>
            </ReferralsProvider>
          </WalletGuard>
        ),
        children: [
          {
            path: ROUTES.WALLET.key,
            element: <Wallet />
          },
          {
            path: ROUTES.WALLET_REFERRAL_PROGRAM.key,
            element: <ReferralProgram />
          },
          {
            path: ROUTES.WALLET_REFERRAL_PROGRAM_SELECT_NETWORK.key,
            element: <ReferralProgramNetworks />
          },
          {
            path: ROUTES.WALLET_REFERRAL_PROGRAM_FORM.key,
            element: <ReferralProgramForm />
          },
          {
            path: ROUTES.WALLET_REFERRAL_PROGRAM_COMPLETE.key,
            element: <ReferralProgramComplete />
          },
          {
            path: ROUTES.WALLET_REFERRAL_PROGRAM_CONFIRM.key,
            element: <ReferralProgramConfirm />
          },
          {
            path: ROUTES.WALLET_LIST.key,
            element: <WalletsList />
          },
          {
            path: ROUTES.WALLET_ADD.key,
            element: <AddWallet />,
            children: [
              {
                path: ROUTES.WALLET_ADD_METHOD.key,
                element: <AddWalletMethod />
              },
              {
                path: ROUTES.WALLET_ADD_NEW.key,
                element: <NewWallet />
              }
            ]
          },
          {
            path: ROUTES.SETTINGS.key,
            element: <Settings />
          },
          {
            path: ROUTES.SETTINGS_CHANGE_EMAIL.key,
            element: <ChangeEmailForm />
          },
          {
            path: ROUTES.SETTINGS_VERIFICATION_EMAIL_CODE.key,
            element: <VerificationEmailCode />
          },
          {
            path: ROUTES.SETTINGS_VERIFICATION_EMAIL_STATUS.key,
            element: <VerificationEmailStatus />
          },
          {
            path: ROUTES.SETTINGS_NEW_EMAIL.key,
            element: <NewEmailForm />
          },
          {
            path: ROUTES.SETTINGS_NEW_EMAIL_CODE.key,
            element: <NewEmailCodeForm />
          },
          {
            path: ROUTES.SETTINGS_LANGUAGE.key,
            element: <LanguagesSettings />
          },
          {
            path: ROUTES.SETTINGS_PRIVATE_KEY.key,
            element: <SettingsPrivateKey />
          },
          {
            path: ROUTES.SETTINGS_LOGOUT.key,
            element: <Logout />
          },
          {
            path: ROUTES.TWO_FA_ACTIVATE.key,
            element: <TwoFaActivate />
          },
          {
            path: ROUTES.TWO_FA_CHANGE.key,
            element: <TwoFaChange />
          },
          {
            path: ROUTES.TWO_FA_DISABLE.key,
            element: <TwoFaDisable />
          },
          {
            path: ROUTES.TWO_FA_EMAIL.key,
            element: <TwoFaEmail />
          },
          {
            path: ROUTES.STAKING.key,
            element: <Staking />
          },
          {
            path: ROUTES.EARM.key,
            element: <Earm />
          },
          {
            path: ROUTES.ASSET.key,
            element: <AssetPage />
          },
          {
            path: ROUTES.ASSET_DETAILS.key,
            element: <AssetDetailsPage />
          },
          {
            path: ROUTES.TRANSACTION_HISTORY.key,
            element: <TransactionsPage />
          },
          {
            path: ROUTES.DEPOSIT.key,
            element: <Deposit />,
            children: [
              { path: ROUTES.DEPOSIT_ASSETS_LIST.key, element: <DepositList /> },
              { path: ROUTES.DEPOSIT_ADDRESS.key, element: <DepositAddress /> }
            ]
          },
          {
            path: ROUTES.SEND.key,
            element: <Send />,
            children: [
              { path: ROUTES.SEND_ASSETS_LIST.key, element: <SendAssetsList /> },
              { path: ROUTES.SEND_CURRENCY.key, element: <SendCurrency /> },
              { path: ROUTES.SEND_CURRENCY_CONFIRM.key, element: <SendConfirm /> },
              { path: ROUTES.SEND_CURRENCY_CHANGE_SPEED.key, element: <SendChangeSpeed /> }
            ]
          },
          {
            path: ROUTES.SWAP.key,
            element: (
              <SwapHistoryProvider>
                <Swap />
              </SwapHistoryProvider>
            ),
            children: [
              { path: ROUTES.SWAP_SUCCESS.path, element: <SwapSuccess /> },
              { path: ROUTES.SWAP_ERROR.path, element: <SwapError /> },
              { path: ROUTES.SWAP_EXCHANGE.path, element: <SwapExchange /> },
              { path: ROUTES.SWAP_ASSETS.path, element: <SwapAssets /> },
              { path: ROUTES.SWAP_ACCOUNTS.path, element: <SwapAccounts /> },
              { path: ROUTES.SWAP_OFFERS.path, element: <SwapOffers /> },
              { path: ROUTES.SWAP_CONFIRM.path, element: <SwapConfirm /> },
              { path: ROUTES.SWAP_HISTORY.path, element: <SwapHistory /> },
              { path: ROUTES.SWAP_TRANSACTION.path, element: <SwapTransaction /> }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
])
